$color-light: #fff;

.light-sidebar {
  &:not(.sidebar-mini) {
    .sidebar-style-2 .sidebar-menu>li.active {
      ul.dropdown-menu li a {
        background-color: $color-light !important;
      }
    }
  }

  &.sidebar-mini .main-sidebar {
    .sidebar-menu {
      background-color: $color-light;

      li {
        &.active {
          a {
            box-shadow: none;
            color: #000000;
            margin: 0px;
            border-radius: 3px;
          }
        }

        ul.dropdown-menu {
          li {
            a {
              background-color: $color-light;
              padding-left: 30px;

              &::before {
                left: 10px;
              }
            }

            &:hover a {
              background-color: $color-light;
            }
          }
        }

        .has-dropdown:hover {
          margin: 0px;
          border-radius: 3px;
        }
      }
    }

    .sidebar-user {
      background-color: $color-light;
    }

    .dropdown-title {
      background-color: $color-light;
    }

    &:after {
      background-color: $color-light;
    }
  }

  .navbar {
    &.active {
      background-color: #fff;
    }

    .form-inline {
      .form-control {
        background-color: #f2f2f2;
      }

      .btn {
        background-color: #f2f2f2;
      }
    }
  }

  .main-sidebar .sidebar-menu li {
    &.active a {
      color: #000000;
      background-color: rgba(146, 144, 144, 0.28);
    }

    ul.dropdown-menu li {
      &.active>a {
        color: #454546;
        font-weight: 600;

        &::before {
          color: #454546;
        }
      }

      a:hover {
        color: #454546;

        &::before {
          color: #454546;
        }
      }
    }
  }
}